import React from 'react';
import { errorToast, successToast } from '../../api/Util';
import ConfirmationDialog from '../ConfirmationDialog';
import { axiosClient } from '../../api/httpClient';
import { SET_YT_HELPER_ENABLE_MODAL, SET_UPDATED_YT_HELPER_STATUS } from '../../actions/types';
import { useDispatch, useSelector } from 'react-redux';

const EnableYtHelper = () => {
    const dispatch = useDispatch();
    const { CardYtHelperEnableModal, currentCardData } = useSelector((state) => state.kanbanFilterReducer);

    const updateYtEnableHelper = () => {
        dispatch({ type: SET_YT_HELPER_ENABLE_MODAL, payload: true });
        let obj = {
            client_id: currentCardData?.client_id,
            card_id: currentCardData?.card_id,
        };
        axiosClient.post(`/api/card/${currentCardData?.card_id}/enableCardYtHelper`, obj)
            .then(({ data }) => {
                successToast("Card YtHelper Enabled successfully!!");
                dispatch({ type: SET_UPDATED_YT_HELPER_STATUS, payload: true });
            })
            .catch((err) => {
                errorToast("Something went wrong, please try again");
            })
    }

    return (
        <ConfirmationDialog
            onNegative={() => { dispatch({ type: SET_YT_HELPER_ENABLE_MODAL, payload: false })}}
            onPositive={() => { updateYtEnableHelper() }}
            confirmationHeader="Confirm"
            confirmationDialogId={"confirm-ytHelper-enable"}
            confirmation={CardYtHelperEnableModal}
            confirmationText={"Are you sure want to enable the ytHelper ?"}
        />
    );
}

export default EnableYtHelper;
