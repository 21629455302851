import React, { useEffect, useState } from 'react';
import { CollapsibleItem, Icon, Row, Col, Modal, Container } from "react-materialize";
import RequestCard from "./RequestCard";
import EnableRepurpose from './EnableRepurpose/RepurposeModal';
import EmptyIcon from "./img/empty-data.png";
import { axiosClient } from '../api/httpClient';
import InfiniteScroll from 'react-infinite-scroller';
import KanbanFilters from './Home/KanbanView/KanbanViewContent/KanbanFilters';
import { YT_HELPER_SECTION } from '../api/Constants';
import { convertServerToLocalTime, toISODate } from '../api/Util';
import { Link } from 'react-router-dom';
import { Spin } from "antd";
import ActivityStatusModal from './common/ActivityStatusModal';
import EnableYtHelper from './EnableYtHelper/EnableYtHelper';

export default (props) => {
    const [iconState, setIconState] = useState({
        up: false,
        down: true,
        reset: false,
    });
    const [cardData, setCardData] = useState(props.cards || []);
    const [offset, setOffSet] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [cardActivityModal, setCardActivityModal] = useState(false);
    const [cardActivityData, setCardActivityData] = useState([]);
    const [cardRepurposeModal, setCardRepurposeModal] = useState(false);
    const [currentCardId, setCurrentCardId] = useState(null);
    const [currentCardRepurpose, setCurrentCardRepurpose] = useState(true);

    useEffect(() => {
        setCardData(props.cards);
        setOffSet(10);
        if( props.cards && props.cards.length<8)
        {
            setHasMore(false);
        }
    }, [props.cards])

    let section = "";
    let dataSection = "";


    if (props.name === "Video Requests") {
        section = "video_request"
        dataSection = "video_requests"
    }
    else if (props.name === "On Hold") {
        section = "on_hold"
        dataSection = "on_hold"
    }
    else if (props.name === "Editing") {
        section = "editing"
        dataSection = "editing"
    }
    else if (props.name === "QA") {
        section = "qa"
        dataSection = "qa_cards"
    }
    else if (props.name === "Done") {
        section = "done"
        dataSection = "done_cards"
    }
    else if (props.name === "Canceled") {
        section = "canceled"
        dataSection = "cancelled_cards"
    }
    else if (props.name === "Repurposing Done") {
        section = "repurposeDone"
        dataSection = "repurposeDone"
    }
    else if (props.name === "Repurposing QA") {
        section = "repurposeQA"
        dataSection = "repurposeQA"
    }
    else if (props.name === "Repurposing") {
        section = "repurposeEditing"
        dataSection = "repurposeEditing"
    }
    else {
        section = "video_request";
        dataSection = "video_requests";
    }

    if(props?.section === YT_HELPER_SECTION)
    {
        if (props.name === "Assigned") {
            section = "assigne_card"
            dataSection = "assigne_card"
        }
        else if (props.name === "In Progress") {
            section = "inProgress"
        }
        else if (props.name === "Done") {
            section = "inDone"
        }
        else if(props.name === "Cancelled"){
            section = 'cancelled'
        }
    }

    const fetchMoreCards =()=>{
        let params = props.viewAs ? {
            view_as: props.viewAs
        } : {};
        let obj = {
            offset,
            limit:10,
            section,
            card_name: props.searchText ? props.searchText:""
        }
        if(props.startDate && props.endDate)
        {
            obj = {
                ...obj,
                startDate:toISODate(props.startDate),
                endDate:toISODate(props.endDate),
                selectedDueDate:props.selectedDueDate
            }
        }
        let newOffset = offset+10;
        if(props?.section === YT_HELPER_SECTION)
        {
            axiosClient.post("/api/ythelper/cards",{...params,...obj})
            .then(({data})=>{
                if(data[section].length !== 0 )
                {
                    let allCardData = [...cardData ,...data[section]];
                    setCardData(allCardData);
                    setOffSet(newOffset);
                }
                else{
                    setHasMore(false);
                }
            return;
            })
        }
        else
        {
            axiosClient.post("/api/getCards",{...params,...obj})
            .then(({data})=>{
                if(data[dataSection].length !== 0 )
                {
                    let allCardData = [...cardData ,...data[dataSection]];
                    setCardData(allCardData);
                    setOffSet(newOffset);
                }
                else{
                    setHasMore(false);
                }
            return;
            })
        }
        return
    }

    const filterCardByDate =(dataFilter)=>{
        let params = props.viewAs ? {
            view_as: props.viewAs
        } : {};
        if (dataFilter) {
            params.dataFilter = dataFilter;
        }
        let obj = {
            offset:0,
            limit:10,
            section,
            card_name: props.searchText ? props.searchText:"",
        }
        if (props.startDate && props.endDate) {
            obj = {
                ...obj,
                startDate: toISODate(props.startDate),
                endDate: toISODate(props.endDate),
                selectedDueDate: props.selectedDueDate
            }
        }
        axiosClient.post("/api/getCards", { ...params, ...obj })
        .then(({ data }) => {
            if (data[dataSection] && data[dataSection].length !== 0) {
                setCardData(data[dataSection]);
            } else {
                setCardData([]);
            }
        })
        .catch(error => {
            console.error("Failed to fetch cards:", error);
            setCardData([]); 
        });
    }

    const handleFilterClick = (type) => {
        if (type === 'down') {
            setIconState({ up: true, down: false, reset: true });
            filterCardByDate('down'); // Pass 'down' as a parameter
        } else if (type === 'up') {
            setIconState({ up: false, down: true, reset: true });
            filterCardByDate('up'); // Pass 'up' as a parameter
        } else {
            setIconState({ up: false, down: true, reset: false });
            filterCardByDate(); // Call refresh without parameters
        }
    }

    return (
        <>
        <KanbanFilters iconState={iconState} onFilterClick={handleFilterClick} section={section} className='filtersIconKanban' />
        <CollapsibleItem
            className="cardList"
            expanded={true}
            header={props.name}
            icon={<Icon>arrow_drop_down</Icon>}
            node="div"
            onSelect={() => { }}
            style={{ overflowX: "scroll" }}
        >
            {!cardData &&
                <div style={{ textAlign: "center", marginTop: "22px" }}>
                    <Spin />
                </div>
            }
            {cardData && <InfiniteScroll
                pageStart={0}
                hasMore={hasMore}
                useWindow={false}
                loadMore={fetchMoreCards}
            >
                <div className="drag-container" data-card-status={props.type} data-card-repurpose-status={props.rType}
                    data-ythelper-card-status = {props.ytType}
                    style={{ minHeight: "10vh", minWidth: "300px" }}>
                    {cardData && cardData.map((card, index) => {
                        return (
                            <div data-paused={card.paused} data-complete={card.is_complete} data-card-id={card.card_id}
                                data-card-status={card.card_status} key={`card-${card.card_id}`} data-content-id={card.content_id || ""}
                                data-done-video-limit={ card?.done_video_limit ? card.done_video_limit : "1" }
                                data-ythelper-card-status = {props?.section === YT_HELPER_SECTION ? (card.ytHelper_card_status ? card.ytHelper_card_status: props.ytType):null}
                                data-card-repurpose-status={card.repurpose_status}
                                data-index={index} data-revisions={card.revision_nr ? card.revision_nr : 0}>
                                <Link className="linkTextStyle" to={{
                                    pathname: `/card-details/${card.card_id}`,
                                    state: { cardId: card.card_id }
                                }}>
                                <RequestCard setCardActivityModal={setCardActivityModal} setCardActivityData={setCardActivityData} viewCard={props.viewCard} card={card} section={props?.section} setCardRepurposeModal={setCardRepurposeModal} setCurrentCardId={setCurrentCardId} currentCardRepurpose={currentCardRepurpose} />
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </InfiniteScroll>}
        </CollapsibleItem>
        <ActivityStatusModal cardActivityModal={cardActivityModal} setCardActivityModal={setCardActivityModal} cardActivityData={cardActivityData}/>
        <EnableRepurpose cardRepurposeModal={cardRepurposeModal} setCardRepurposeModal={setCardRepurposeModal} currentCardId={currentCardId} setCurrentCardRepurpose={setCurrentCardRepurpose}/>
        <EnableYtHelper />
        </>
    );
};
