import React from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import * as actions from "../../../../actions";
import Clock from 'react-live-clock';
import {
	Button,
	CardPanel,
	Checkbox,
	Col,
	Dropdown,
	Icon,
	Row,
} from "react-materialize";
import { Select } from 'antd';
import { isAdmin, isSubAdmin, isCustomer, isTeamLead, getUserShortName, toISODate } from "../../../../api/Util";
import { KANBAN_SET_START_DATE, KANBAN_SET_END_DATE, KANBAN_SET_VIEW_AS, KANBAN_SET_SHOW_CANCELED, KANBAN_SET_SHOW_HOLD, KANBAN_SET_SHOW_REPURPOSED, KANBAN_CLEAR_FILTERS, KANBAN_SET_SEARCH_TEXT, KANBAN_SET_DUE_DATE, KANBAN_CLEAR_FILTERS_STATE } from '../../../../actions/types';
import * as _ from 'lodash';
import { QA_USER, YT_HELPER, SUB_ADMIN, CUSTOMER } from '../../../../api/Constants';
import styles from './KanbanViewHeaderStyle.module.scss';
import { Link } from 'react-router-dom';
import SearchBar from '../../../SearchBar';
import CustomDatePicker from '../../../common/DatePicker';

export default connect(mapStateToProps, actions)((props) => {
	const dispatch = useDispatch();
	const websiteSlug = props?.subDomain?.subDomainData?.website_slug;
	const loggedInUser = props.auth?.loggedInUser || {};
	const admin = isAdmin(loggedInUser);
	const subAdmin = isSubAdmin(loggedInUser);
	const customer = isCustomer(loggedInUser);
	const teamLead = isTeamLead(loggedInUser);
	const { startDate, endDate, viewAs, showCanceled, showHold, showRepurposed, selectedDueDate, searchText } = useSelector((state) => state.kanbanFilterReducer);
	const websiteUsers = useSelector(state => state?.allWebsiteUsers?.website_users);
	const requestsLimited = customer && loggedInUser.request_limit;
	const accountPending = customer && loggedInUser.qas?.length <= 0;
	const viewType = localStorage.getItem("viewType");

	function setViewAs(event) {
		dispatch({ type: KANBAN_SET_VIEW_AS, payload: event });
		localStorage.setItem('viewAs', event)
		refresh(event);
	}

	function clearFilter() {
		dispatch({ type: KANBAN_CLEAR_FILTERS });
		dispatch({ type: KANBAN_CLEAR_FILTERS_STATE, payload: true });
	}

	const refresh = (user) => {
		dispatch({ type: KANBAN_SET_SEARCH_TEXT, payload: "" });
		let obj = {
			offset: 0,
			limit: 10,
			section: "",
			card_name: searchText ? searchText : "",
			viewType:viewType,
		}
		if (startDate && endDate) {
			obj = {
				...obj,
				startDate: toISODate(startDate),
				endDate: toISODate(endDate),
				selectedDueDate
			}
		}

		props.fetchCards(obj, user || viewAs?.value);
	};

	const activeFilterCount = () => {
		let count = 0;
		if (startDate && endDate) count++;
		if (viewAs) count++;
		if (searchText) count++;
		return count;
	};

	const searchCards = () => {
		let obj = {
			offset: 0,
			limit: 10,
			section: "",
			card_name: searchText ? searchText : "",
			viewType: viewAs?.value,
		}
		localStorage.setItem('searchText', searchText)
		props.fetchCards(obj, viewAs);
	}
	return (
		<>
			<div className={`aside-right-top-bar ${styles.searchBarAndBtns}`}>
				<div className={`top-bar-left ${styles.topBarLeftStyle}`}>
					<div className={`refresh-create-btn ${styles.refreshAndCreateBtn}`}>
						<Button icon={<Icon>refresh</Icon>} tooltip="Reload Cards" onClick={() => refresh()}
							className="btn btn-primary" />
						{
							(admin || customer) &&
							<Link to={{ pathname: `/new-card` }}>
								<Button icon={<Icon>add</Icon>}
									tooltip={requestsLimited ? `You have reached limit for video requests. Your limit will refresh on the next billing date. For further information contact your account manager.`
										: accountPending ? "We are setting up your account for file sharing, we will update you via email once it is done."
											: "Create New Video Request"}
									className={"btn btn-primary" + ((requestsLimited || accountPending)
										? "btn-secondary" : "btn-primary")} />
							</Link>
						}
					</div>
				</div>
				<div className="top-bar-center">
					<div className="top_bar">
						<SearchBar cards={props.cardList} setSearchText={(text) => dispatch({ type: KANBAN_SET_SEARCH_TEXT, payload: text })} searchText={searchText} searchCards={searchCards} />
					</div>
				</div>
			</div>
			<div className={`aside-top-filters  ${styles.filtersDiv}`}>
				<Row className={`hide-on-med-and-down difplay_flext ${websiteSlug}`}>
					{
						loggedInUser.client_type !== CUSTOMER && viewType === "normal" ?
							<Col className="date-filter-col">
								<CustomDatePicker className="date-picker-kanban-view"
									setStartDate={(date) => dispatch({ type: KANBAN_SET_START_DATE, payload: date })}
									setEndDate={(date) => dispatch({ type: KANBAN_SET_END_DATE, payload: date })}
									startDate={startDate}
									endDate={endDate}
									setSelectedDueDate={(date) => dispatch({ type: KANBAN_SET_DUE_DATE, payload: date })}
									selectedDueDate={selectedDueDate}
								/>
							</Col> : null
					}
					{
						(admin || subAdmin || teamLead) &&
						<Col className="view-input-col">
							<Select className="z-depth-1 select-view-as-dashboard"
								showSearch
								value={(websiteUsers?.length) ? (viewAs ? parseInt(viewAs) : undefined) : undefined}
								onChange={setViewAs}
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
								options={_.concat(
									[{
										...loggedInUser, value: loggedInUser.client_id, label: `Me (${loggedInUser.client_type})`
									}], websiteUsers.map(user => ({ value: user.client_id, label: user.client_type === 'customer' ? `${user.fullname} (${user.client_type})` : `${getUserShortName(user.fullname)} (${user.client_type})`,
										className: user?.is_deleted ? 'deleted-user' : ''		
									}))
								)}
								placeholder="View As"
							/>
						</Col>
					}
					<Col>
						<CardPanel className="toggle-panel">
							<Checkbox value="1" label="Show Canceled Cards" checked={showCanceled} id="check-canceled"
								onChange={(e) => dispatch({ type: KANBAN_SET_SHOW_CANCELED, payload: e.target.checked })} />
						</CardPanel>
					</Col>
					<Col>
						<CardPanel className="toggle-panel">
							<Checkbox value="2" label="Show Cards On Hold" checked={showHold} id="check-hold"
								onChange={(e) => dispatch({ type: KANBAN_SET_SHOW_HOLD, payload: e.target.checked })} />
						</CardPanel>
					</Col>
					<Col>
						<CardPanel className="toggle-panel">
							<Checkbox value="2" label="Show Cards In Repurpose" checked={showRepurposed} id="check-rp"
								onChange={(e) => dispatch({ type: KANBAN_SET_SHOW_REPURPOSED, payload: e.target.checked })} />
						</CardPanel>
					</Col>
					<Col className="real-date">
						<CardPanel style={{ margin: 0, borderRadius: "8px" }}>
							<Icon left>access_time</Icon>
							<Clock
								format={"dddd, DD-MMM-YYYY | hh:mm:ss"}
								ticking={true}
								interval={1000}
								timezone={websiteSlug === "snappyads" ? process.env.REACT_APP_TIMEZONE_SNAPPY : process.env.REACT_APP_TIMEZONE}
							/>
							<span>{websiteSlug === "snappyads" ? "GMT" : "EST"}</span>
						</CardPanel>
					</Col>
					{
						customer && (props.cards.monthlyCardCount || props.cards.monthlyCardCount === 0) ? <Col>
							<CardPanel className='month-card-created-cardpanel'>
								<span>Monthly Card Created Count : </span>
								<span style={{ margin: '0 4px 0px 4px' }}>{props.cards.monthlyCardCount} </span>
							</CardPanel>
						</Col> : null
					}
					<Col className="cutomer-filter-clear-btn">
						<Button className='clear-filter-btn' style={{ height: '44px' }} onClick={() => clearFilter()}>Clear Filter{activeFilterCount() > 0 && `(+${activeFilterCount()})`}</Button>
					</Col>
				</Row>
				<Row className="hide-on-large-only hide-on-extra-large-only mobile-control-btns">
					<Col s={2} m={4}>
						<Dropdown
							trigger={<Button style={{ backgroundColor: "#82b150", marginTop: "5px" }} floating large icon={<Icon>more_vert</Icon>} />}
							options={{
								autoTrigger: false,
								constrainWidth: false,
								coverTrigger: false,
								closeOnClick: false,
							}}>
							{React.Children.toArray([
								(admin || subAdmin || customer) && !(requestsLimited || accountPending) ?
									<Button icon={<Icon left>add</Icon>} flat style={{ margin: '5px' }}>
										Create New Request</Button> : null,
								accountPending ?
									<p style={{ margin: '20px' }}>Your DropBox video account is in progress, we will send you an email when we are done then you will be able create video request.</p>
									: null,
								requestsLimited ?
									<p style={{ margin: '20px' }}>You have reached limit for video requests. Your limit will refresh on the next billing date. For further information contact your account manager.</p>
									: null,
								<Button icon={<Icon left>refresh</Icon>} flat onClick={() => refresh()} style={{ margin: '5px' }}>Refresh</Button>,
								<div style={{ margin: '20px' }}>
									<Checkbox value="1" label="Show Canceled Cards" checked={showCanceled} id="check-canceled"
										onChange={(e) => dispatch({ type: KANBAN_SET_SHOW_CANCELED, payload: e.target.checked })} />
								</div>,
								<div style={{ margin: '20px' }}>
									<Checkbox value="2" label="Show Cards On Hold" checked={showHold} id="check-hold"
										onChange={(e) => dispatch({ type: KANBAN_SET_SHOW_HOLD, payload: e.target.checked })} />
								</div>,
								<div style={{ margin: '20px' }}>
									<Checkbox value="2" label="Show Cards In Repurpose" checked={showRepurposed} id="check-repurp-2"
										onChange={(e) => dispatch({ type: KANBAN_SET_SHOW_REPURPOSED, payload: e.target.checked })} />
								</div>,
								(admin || subAdmin || isTeamLead(loggedInUser)) &&
								<Select
									className="z-depth-1 select-view-as-dashboard"
									showSearch
									value={(websiteUsers?.length) ? (viewAs ? parseInt(viewAs) : undefined) : undefined}
									onChange={setViewAs}
									optionFilterProp="children"
									filterOption={(input, option) =>
										(option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
									}
									options={_.concat(
										[{
											...loggedInUser, value: loggedInUser.client_id, label: `Me (${loggedInUser.client_type})`
										}], websiteUsers.map(user => ({ value: user.client_id, label: user.client_type === 'customer' ? `${user.fullname} (${user.client_type})` : `${getUserShortName(user.fullname)} (${user.client_type})`,
										className: user?.is_deleted ? 'deleted-user' : '' }))
									)}
									placeholder="View As"
								/>
							])}
						</Dropdown>
					</Col>
					<Col s={10} m={8}>
						<CardPanel style={{ margin: 0, fontSize: "1.2rem" }}>
							<Icon left>access_time</Icon>
							<Clock
								format={"DD-MMM-YYYY hh:mm:ss"}
								ticking={true}
								interval={1000}
								timezone={websiteSlug === "snappyads" ? process.env.REACT_APP_TIMEZONE_SNAPPY : process.env.REACT_APP_TIMEZONE}
							/>
							<span>{websiteSlug === "snappyads" ? "GMT" : "EST"}</span>
						</CardPanel>
					</Col>
				</Row>
			</div>
		</>
	);
});

function mapStateToProps({ cards, auth, subDomain, users, settings }) {
	return { cards, auth, subDomain, users, settings };
}
