import React, { useEffect, useState } from 'react';
import { DatePicker, Checkbox } from 'antd'; 
import dayjs from 'dayjs'; 
import { rangePresets } from '../../api/Util';

const CustomDatePicker = ({ className, setStartDate, setEndDate, startDate: oldStartDate, endDate: oldEndDate, setSelectedDueDate, selectedDueDate }) => {
  const [startDate, setInternalStartDate] = useState(oldStartDate);
  const [endDate, setInternalEndDate] = useState(oldEndDate);

  const handleDateRange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(new Date(dateStrings[0]));
      setEndDate(new Date(dateStrings[1]));
      setInternalStartDate(new Date(dateStrings[0]));
      setInternalEndDate(new Date(dateStrings[1]));
    } else {
      setStartDate(null);
      setEndDate(null);
      setInternalStartDate(null);
      setInternalEndDate(null);
    }
  };

  const onOpenChange = (open) => {
    if (open) {
      setInternalStartDate(null);
      setInternalEndDate(null);
    } else {
      if (!startDate && !endDate) {
        setInternalStartDate(oldStartDate);
        setInternalEndDate(oldEndDate);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    setSelectedDueDate(e.target.checked);
  };

  useEffect(() => {
    setInternalStartDate(oldStartDate);
  }, [oldStartDate]);

  useEffect(() => {
    setInternalEndDate(oldEndDate);
  }, [oldEndDate]);

  return (
    <DatePicker.RangePicker
      allowClear
      presets={rangePresets}
      className={`removeInputSearchBoxBorder ${className}`}
      onChange={handleDateRange}
      onOpenChange={onOpenChange}
      value={[
        startDate ? dayjs(startDate) : null,
        endDate ? dayjs(endDate) : null,
      ]}
      renderExtraFooter={() => (
        setSelectedDueDate && selectedDueDate !== undefined ? (
          <Checkbox checked={selectedDueDate} onChange={handleCheckboxChange}>
            Set as Due Date
          </Checkbox>
        ) : null
      )}
    />
  );
};

export default CustomDatePicker;
