import {combineReducers} from "redux";
import authReducer from "./authReducer";
import cardReducer from "./cardReducer";
import userReducer from "./userReducer";
import subDomainReducer from "./subDomainReducer";
import {USER_LOGOUT} from "../actions/types";
import {setAuthToken, setParentId, setWebsiteType} from "../api/httpClient";
import settingsReducer from "./settingsReducer";
import whiteLabelCustomersReducer from "./whiteLabelCustomersReducer";
import websiteReducer from "./websiteReducer";
import RatingReducer from "./ratingReducer";
import inboxReducer from "./inboxReducer";
import KanbanViewReducer from "./KanbanViewReducer";

const appReducer = combineReducers({
    auth: authReducer,
    cards: cardReducer,
    users: userReducer,
    settings: settingsReducer,
    subDomain: subDomainReducer,
    whiteLabelCustomers: whiteLabelCustomersReducer,
    allWebsiteUsers: websiteReducer,
    ratingData: RatingReducer,
    inboxSeenMessages: inboxReducer,
    kanbanFilterReducer: KanbanViewReducer,
});

const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
        state = undefined;
        setAuthToken();
        setWebsiteType();
        setParentId();
    }
    return appReducer(state, action);
}

export default rootReducer;
