// Action Types
import { update } from "lodash";
import {
  KANBAN_SET_VIEW_AS,
  KANBAN_SET_SHOW_CANCELED,
  KANBAN_SET_SHOW_HOLD,
  KANBAN_SET_SHOW_REPURPOSED,
  KANBAN_CLEAR_FILTERS,
  KANBAN_SET_START_DATE,
  KANBAN_SET_END_DATE,
  KANBAN_SET_SEARCH_TEXT,
  KANBAN_SET_DUE_DATE,
  KANBAN_CLEAR_FILTERS_STATE,
  SET_YT_HELPER_ENABLE_MODAL,
  SET_CURRENT_CARD_DATA,
  SET_UPDATED_YT_HELPER_STATUS,
} from "../actions/types";

const initialState = {
  viewAs: null,
  showCanceled: false,
  showHold: true,
  showRepurposed: true,
  startDate: null,
  endDate: null,
  selectedDueDate: false,
  clearFilterState: false,
  searchText: "",
  CardYtHelperEnableModal:false,
  currentCardData: {},
  updatedYtHelperStatus: false,
};

export default function (state = initialState, { payload, type }) {
  switch (type) {
    case KANBAN_SET_VIEW_AS:
      return { ...state, viewAs: payload };
    case KANBAN_SET_SHOW_CANCELED:
      return { ...state, showCanceled: payload };
    case KANBAN_SET_SHOW_HOLD:
      console.log(payload,'paylOAdReducer');
      return { ...state, showHold: payload };
    case KANBAN_SET_SHOW_REPURPOSED:
      return { ...state, showRepurposed: payload };
    case KANBAN_SET_START_DATE:
      return { ...state, startDate: payload };
    case KANBAN_SET_END_DATE:
      return { ...state, endDate: payload };
    case KANBAN_SET_SEARCH_TEXT:
      return { ...state, searchText: payload };
    case KANBAN_SET_DUE_DATE:
      return { ...state, selectedDueDate: payload };  
    case KANBAN_CLEAR_FILTERS:
      localStorage.removeItem("viewAs");
      localStorage.removeItem("searchText");
      localStorage.removeItem("showCanceled");
      localStorage.removeItem("showHold");
      localStorage.removeItem("showRepurposed");
      return {
        ...state,
        viewAs: null,
        showCanceled: false,
        showHold: true,
        showRepurposed: true,
        startDate: null,
        endDate: null,
        selectedDueDate: false,
        searchText: "",
      };
     case KANBAN_CLEAR_FILTERS_STATE:
      return { ... state, clearFilterState: payload};
      case SET_YT_HELPER_ENABLE_MODAL:
        return { ...state, CardYtHelperEnableModal: payload };
      case SET_CURRENT_CARD_DATA:
        return { ...state, currentCardData: payload };
      case SET_UPDATED_YT_HELPER_STATUS:
        return { ...state, updatedYtHelperStatus: payload }
    default:
      return state;
  }
};
