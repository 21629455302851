import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Container } from "react-materialize";

import EmptyIcon from "../img/empty-data.png";
import { getUserShortName, isMobile } from "../../api/Util";

const ActivityStatusModal = ({
  cardActivityModal,
  setCardActivityModal,
  cardActivityData,
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <Modal
      header={"Card Activity"}
      id="cardActivityModal"
      open={cardActivityModal}
      className={`${!isMobile ? "modalHeight" : ""} cardActivityModal`}
      style={{ width: isMobile ? "90%" : "50%" }}
      options={{
        dismissible: true,
        endingTop: "10%",
        opacity: 0.5,
        preventScrolling: true,
        onCloseEnd: () => {
          setCardActivityModal(false);
        },
      }}
    >
      <Container style={{ backgroundColor: "#F6F7F7" }}>
        {cardActivityData?.length > 0 ? (
          cardActivityData?.map((item) => {
            return (
              <div>
                <Row className="row-card-activity">
                  <Col s={isMobile ? 24 : 4} className="col-card-activity">
                    <div className="card-activity-initials">
                      <span>
                        {item.user_id === 0
                          ? "AM"
                          : item.firstname?.charAt(0) +
                            item.lastname?.charAt(0)}
                      </span>
                    </div>
                    <div>
                      <span className="card-activity-primary-text">
                        {item.user_id === 0
                          ? "Auto Movement"
                          : item.client_type !== "customer"
                          ? getUserShortName(item.firstname, item.lastname)
                          : item.firstname + " " + item.lastname}
                      </span>
                      <br />
                      <span
                        className="card-activity-secondary-text"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.client_type}
                      </span>
                    </div>
                  </Col>
                  <Col s={isMobile ? 24 : 8}>
                    <span
                      className="card-activity-primary-text"
                      dangerouslySetInnerHTML={{
                        __html: item.card_activity_status,
                      }}
                    ></span>
                    <br />
                    <span className="card-activity-secondary-text">
                      {item.created_at}
                    </span>
                  </Col>
                </Row>
              </div>
            );
          })
        ) : (
          <div className="empty-card-activity">
            <p>No Activity is Present</p>
            <img src={EmptyIcon} alt="Empty Icon" />
          </div>
        )}
      </Container>
    </Modal>
  );
};

export default ActivityStatusModal;
