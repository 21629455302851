import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import * as actions from "../actions";
import { Card } from 'antd';
import {
    CardPanel,
    Col,
    Collapsible,
    CollapsibleItem,
    Container,
    Icon,
    ProgressBar,
    Row,
} from "react-materialize";
import * as _ from 'lodash';
import CardList from "./CardList";
import EditCard from "./EditCard";
import { cssStringToObj, isAdmin, isSubAdmin, isCustomer, isTeamLead, toISODate } from "../api/Util";
import {CANCELED, DONE, EDITING, ON_HOLD, QA, REPURPOSE_QA, VIDEO_REQUEST } from "../api/Constants";
import DragNDrop from "./DragNDrop";
import PubSub from 'pubsub-js';
import {CARD_CLICKED} from "../api/Events";
import {useLocation} from "react-router-dom";
import { fetchCardsBasedOnSection } from '../services/cardsService';
import { useDispatch } from 'react-redux';
import { CARDS_FETCHED, KANBAN_SET_SEARCH_TEXT, KANBAN_SET_VIEW_AS, KANBAN_CLEAR_FILTERS_STATE } from '../actions/types';
import RenderHtml from './common/RenderHtml';
import KanbanHeader from './Home/KanbanView/KanbanViewHeader/KanbanViewHeader';
import TopHeader from './TopHeader/TopHeader';


export default connect(mapStateToProps, actions)((props) => {
    const [key, setKey] = useState(1);
    const [editCardOpen, setEditCardOpen] = useState(false);
    const [viewCardId, setViewCardId] = useState(0);
    const [editCardId, setEditCardId] = useState(0);
    const [cardsData, setCardsData] = useState({});
    const [showBanner, setShowBanner] = useState({});
    const loggedInUser = props.auth?.loggedInUser || {};
    const { startDate, endDate, viewAs, showCanceled, showHold, showRepurposed, selectedDueDate, searchText, clearFilterState } = useSelector((state) => state.kanbanFilterReducer);
    const admin = isAdmin(loggedInUser);
    const subAdmin = isSubAdmin(loggedInUser);
    const customer = isCustomer(loggedInUser);
    const teamLead =isTeamLead(loggedInUser);
    const websiteName = props.subDomain.subDomainData.name;
    const dispatch = useDispatch();

    const showProfileMsg = localStorage.getItem("skipProfileForNow");
    const parentId = localStorage.getItem("parentId");
;
    const refresh = (user) => {
        dispatch({ type: KANBAN_SET_SEARCH_TEXT, payload: "" });
        let obj = {
            offset : 0,
            limit:10,
            section : "",
            card_name: searchText ? searchText:"",
        }
        if(startDate && endDate)
        {
            obj = {
                ...obj,
                startDate:toISODate(startDate),
                endDate:toISODate(endDate),
                selectedDueDate
            }
        }

        props.fetchCards(obj,user || viewAs?.value);
    };

    const showEditCard = (cardId) => {
        setEditCardId(cardId);
        setEditCardOpen(true);
    }
    const showCard = (cardId) => {
        setViewCardId(cardId);
    }

    useEffect(() => {
        const savedSearchText = localStorage.getItem('searchText');
        const savedViewAs = localStorage.getItem("viewAs");
        switch (true) {
            case !!savedSearchText:
                console.log(savedSearchText, 'savedSearchText');
                dispatch({ type: KANBAN_SET_SEARCH_TEXT, payload: savedSearchText });
                break;

            case !!savedViewAs:
                dispatch({ type: KANBAN_SET_VIEW_AS, payload: savedViewAs });
                break;

            default:
                break;
        }
    }, []);

    useEffect(() => {
        const subToken = PubSub.subscribe(CARD_CLICKED, (msg, data) => showCard(data));

        if(_.isEmpty(props.settings)) {
            props.fetchSettings();
        }

        if(((admin &&  parentId !== '0') || (teamLead & parentId !== '0') ) && ((props.users.qas == null) && (parentId !== '0')) && ((!props.users.loadingUsers) && (parentId !== '0'))) {
            props.fetchAllUsers();
        }
        if (((admin &&  parentId !== '0') || (subAdmin && parentId !== '0' ) || (teamLead && parentId !== '0') ) && ((props.users.customers === undefined) && (parentId !== '0')) && ((!props.users.loadingCustomers) && (parentId !== '0'))) {
            props.fetchAllCustomers();
        }
        return () => PubSub.unsubscribe(subToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewAs, admin, subAdmin]);

    useEffect(() => {
        if ((startDate && endDate) || clearFilterState) {
            refresh();
            dispatch({ type: KANBAN_CLEAR_FILTERS_STATE, payload: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, selectedDueDate, clearFilterState])

    const bodyFontStyle = {
        fontWeight: showBanner?.banner_font_style === "b" ? "bold" : "normal",
        fontStyle: showBanner?.banner_font_style === "i" ? "italic" : "normal",
        fontSize: showBanner?.banner_text_style,
        color: "#000000"
      }
      const headingStyle = {
        color: showBanner?.banner_heading_color || "#ffffff",
      };
      const getBannerHeading = (bannerHeadingStyle, bannerHeading) => {
        const headingTag = `${bannerHeadingStyle}`;
        return React.createElement(headingTag, {
          style: { ...bodyFontStyle, ...headingStyle },
          children: bannerHeading,
        });
      };
      
      useEffect(() => {
        const settingsArray = Object.values(props.settings || {});
        const enableBanner = settingsArray?.filter(
          (setting) => setting?.enable_banner === 1
        );
        const filterData = enableBanner?.find((item) => {
          let announcementStartDate = item?.startDate;
          let announcementEndDate = item?.endDate;
          let currentDate = toISODate(new Date());
          if (
            currentDate >= announcementStartDate &&
            currentDate <= announcementEndDate
          ) {
            return item;
          }
        });
        setShowBanner(filterData);
      }, [props.settings]);
    
      const bannerTextStyle = Object.assign(
        cssStringToObj(showBanner?.banner_text_style || ""),
        { whiteSpace: "pre-wrap" }
      );
    

    useEffect(() => {
        let cardStatus = [
            { status: "video_request", key: "video_requests" },
            { status: "on_hold", key: "on_hold" },
            { status: "editing", key: "editing" },
            { status: "qa", key: "qa_cards" },
            { status: "done", key: "done_cards" },
            { status: "canceled", key: "cancelled_cards" },
            { status: "repurposeEditing", key: "repurposeEditing" },
            { status: "repurposeQA", key: "repurposeQA" },
            { status: "repurposeDone", key: "repurposeDone" },
        ];
        cardStatus && cardStatus.map((data, i) => {
            let obj = {
                offset : 0,
                limit:10,
                section : data.status,
                card_name: searchText ? searchText : localStorage.getItem("searchText") || "",
            }
            if (localStorage.getItem("viewAs")) {
                obj = {
                    ...obj,
                    view_as: localStorage.getItem("viewAs"),
                }
            }
            getCards(obj, data.key);
        })
    }, [])
    

    async function getCards(obj, key) {
        const res = await fetchCardsBasedOnSection(obj);
        setCardsData(prevState => ({...prevState, [key]: res.data[key]}));
    }

    useEffect(() => {
        if (Object.keys(cardsData).length > 0) {
            dispatch({
                type: CARDS_FETCHED,
                payload: { status: 200, data: cardsData }
            })
        }
    }, [cardsData])

    return (
        <Container>
            <DragNDrop loggedInUser={loggedInUser} refresh={refresh} setKey={setKey}/>
            <EditCard cardId={editCardId} me={loggedInUser} isOpen={editCardOpen} onClose={() => setEditCardOpen(false)}/>
            {props.cards.loading ? <Row><ProgressBar /></Row> : ""}
            {
            showBanner &&
            <Collapsible style={{ margin: 0 }}>
                <CollapsibleItem
                    className="notification-collapsible"
                    expanded={true}
                    header={'Announcement'}
                    icon={<Icon>arrow_drop_down</Icon>}
                    node="div"
                    style={{ margin: 0 }}
                >
                    <Row style={{ margin: 0}}>
                        <CardPanel className="bg-warning z-depth-4" style={{ margin: 0}}>
                            <Row>
                                <Col s={12}>
                                  <RenderHtml bannerHeading={showBanner?.banner_heading} bannerHeadingTag={showBanner?.banner_heading_style} headingStyle={headingStyle} />
                                </Col>
                            </Row>
                            <Row>
                                <Col s={12} style={bannerTextStyle}>
                                    <p style={bodyFontStyle}>{showBanner?.banner_text || ""}</p>
                                </Col>
                            </Row>
                            {
                                showBanner?.banner_image &&
                                <Row>
                                    <Col s={12}>
                                        <img src={showBanner?.banner_image} alt="Banner"
                                            style={Object.assign({width: "100%", height: "auto"},
                                                cssStringToObj(showBanner?.banner_image_style))}/>
                                    </Col>
                                </Row>
                            }
                        </CardPanel>
                    </Row>
                </CollapsibleItem>
            </Collapsible>
          }
            {
                showProfileMsg && loggedInUser.is_yt_fields_filled === 1 ? <Col className = "profile-note-col">
                    <strong className = "profile-note">Note : </strong>
                    <span className = "profile-note-message">Please update your YouTube profile section. This will help us to create better content for your YouTube channel.   </span>
                </Col>
            : null
            }
            <Card  className='top-header-card'>
                <TopHeader />
            </Card>
            <KanbanHeader />
            <Row className="row right-sec-row">
                <Col s={12} m={12}>
            {/* 4 columns --- */}
            <div className="card-rows">
            <Row className="video-rows" style={{overflowX: "scroll", marginBottom: "0", }}>
                    <Col className="mobile-card-rows" s={4} m={4}>
                        <Collapsible className={`video-requests-list ${websiteName} filters-icon`} accordion>
                            <CardList name="Video Requests" cards={props.cards?.cardList?.video_requests}  viewAs = {viewAs?.value}
                                    viewCard={admin ? showEditCard : showCard} key={"vr-" + key} searchText = {searchText}
                                    startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    type={VIDEO_REQUEST}/>
                        </Collapsible>
                    </Col>
                {
                    showHold &&
                    <Col className="mobile-card-rows" s={4} m={4} >
                        <Collapsible className={`hold-list ${websiteName} filters-icon`} accordion>
                                <CardList name="On Hold" viewCard={showCard} cards={props.cards?.cardList?.on_hold} viewAs = {viewAs?.value}
                                            type={ON_HOLD} key={"oh-" + key} searchText = {searchText}
                                            startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                            </Collapsible>
                    </Col>
                }
                <Col className="mobile-card-rows" s={4} m={4} >
                    <Collapsible className={`editing-list ${websiteName} filters-icon`} accordion>
                        <CardList name="Editing" viewCard={showCard} cards={props.cards?.cardList?.editing} viewAs = {viewAs?.value}
                                    type={EDITING} key={"ed-" + key} searchText = {searchText}
                                    startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                    </Collapsible>
                </Col>
                {
                    !(websiteName === 'vipleadmachine' && customer) ? <Col className="mobile-card-rows" s={4} m={4} >
                    <Collapsible className={`qa-list ${websiteName} filters-icon`} accordion>
                        <CardList name={websiteName === 'snappyAds' ? "Quality Control" : "QA"}  viewCard={showCard} cards={props.cards?.cardList?.qa_cards} viewAs = {viewAs?.value}
                                    type={QA} key={"qa-" + key} searchText = {searchText}
                                    startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                    </Collapsible>
                </Col> : null
                }

                <Col className="mobile-card-rows" s={4} m={4} >
                    <Collapsible className={`done-list ${websiteName} filters-icon`} accordion>
                            <CardList name="Done" viewCard={showCard} cards={props.cards?.cardList?.done_cards} viewAs = {viewAs?.value}
                                        type={DONE} key={"dn-" + key} searchText = {searchText} 
                                        startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                    </Collapsible>
                </Col>
                {
                    showCanceled &&
                    <Col className="mobile-card-rows" s={4} m={4} >
                        <Collapsible className={`canceled-list ${websiteName}`} accordion>
                                    <CardList name="Canceled" viewCard={showCard} cards={props.cards?.cardList?.cancelled_cards} viewAs = {viewAs?.value}
                                                type={CANCELED} key={"cn-" + key} searchText = {searchText} 
                                                startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                        </Collapsible>
                    </Col>
                }
                 {
                    showRepurposed ? React.Children.toArray([
                        <Col className="mobile-card-rows" s={4} m={4} >
                                <Collapsible className={`repurpose-list ${websiteName} filters-icon`} accordion>
                                    <CardList name="Repurposing" viewCard={showCard}
                                                cards={props.cards?.cardList?.repurposeEditing} viewAs = {viewAs?.value}
                                                rType={EDITING} key={"red-" + key} searchText = {searchText} 
                                                startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                                </Collapsible>
                                </Col>,
                                !(websiteName === 'vipleadmachine' && customer) &&  <Col className="mobile-card-rows" s={4} m={4} style={{ display: !(parentId > 0) && 'none' }} >
                                {parentId > 0 ?
                                <Collapsible className={`repurpose-list ${websiteName} filters-icon`} accordion>
                                    <CardList name="Repurposing QA" viewCard={showCard}
                                                cards={props.cards?.cardList?.repurposeQA} viewAs = {viewAs?.value}
                                                rType={REPURPOSE_QA} key={"red-" + key} searchText = {searchText} 
                                                startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                                </Collapsible>
                                : null}
                            </Col>,
                            <Col className="mobile-card-rows" s={4} m={4} >
                                <Collapsible className={`repurpose-list ${websiteName} filters-icon`} accordion>
                                    <CardList name="Repurposing Done" viewCard={showCard}
                                                cards={props.cards?.cardList?.repurposeDone} viewAs = {viewAs?.value}
                                                rType={DONE} key={"rdn-" + key} searchText = {searchText} 
                                                startDate={startDate} endDate={endDate} selectedDueDate={selectedDueDate}
                                    />
                                </Collapsible>
                                </Col>,
                        ]) : null
                 }
                 
            </Row>
            </div>
            </Col>
            </Row>
            </Container>
    );
});

function mapStateToProps({cards, auth, subDomain, users, settings}) {
    return {cards, auth, subDomain, users, settings};
}
