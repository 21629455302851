import React, { useEffect, useState } from "react";
import { Container } from "react-materialize";
import { Button, Empty, Input, Select, Spin, Rate } from "antd";
import TopHeader from "../TopHeader";
import styles from "./rating.module.scss";
import "./rating.scss";
import { getCardsBasedOnTitle, getUpdatedRatingCards, saveNewQAOnCard } from "../../services/cardsService";
import { errorToast, getUserShortName, infoToast, successToast, toISODate } from "../../api/Util";
import { useDispatch, useSelector } from "react-redux";
import Ratings from "react-ratings-declarative";
import { debounce } from 'lodash';
import RatingTable from "./Table";
import RatingFilter from "./filter";
import { TOTAL_RATED_CARDS, UPDATED_RATING_CARDS_DATA } from "../../actions/types";
import { QA_USER } from "../../api/Constants";

const INITIAL_OPTIONS = [
    {
        value: "done",
        label: "Done",
    },
    {
        value: "repurpose",
        label: "Repurpose Done",
    },
]

function Rating() {
    const [cardsData, setCardsData] = useState([]);
    const [selectedCard, setSelectedCard] = useState({});
    const [ratingSection, setRatingSection] = useState();
    const [selectedOldQA, setSelectedOldQA] = useState();
    const [selectedNewQA, setSelectedNewQA] = useState();
    const [options, setOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [rating, setRating] = useState(0);
    const [ratingReason, setRatingReason] = useState();
	const { startDate, endDate, selectedRating, index, limit } = useSelector((state) => state.ratingData);
    const isMobile = window.innerWidth <= 768;
	const dispatch = useDispatch();
    const websiteUsers = useSelector(state => state.allWebsiteUsers.website_users);

    const getCardsByTitle = async () => {
        const res = await getCardsBasedOnTitle(searchQuery);
        if (res.status) {
            setIsLoading(false);
            const temp = res.data?.map((item, i) => {
                return {
                    cardId: item.card_id,
                    cardTitle: item.card_title,
                    qaAssignedTo: item.qa_assigned_to,
                    cardRatingWithQA: item.card_rating?.map((data, j) => (
                        {
                            label: `${data?.card_rating_q_a?.firstname || ""} ${data.card_rating_q_a?.lastname || ""}`,
                            value: data?.rating,
                            key: data.card_type,
                        }
                    )),
                    defaultSections: item.repurpose_aspect ? INITIAL_OPTIONS : [
                        {
                            value: "done",
                            label: "Done",
                        },
                    ]
                }
            })
            setCardsData(temp);
        } else {
            errorToast("Something went wrong");
        }
    }

    useEffect(() => {
        if (searchQuery !== null && searchQuery !== undefined && searchQuery !== "") {
            setIsLoading(true);
            debounced();
        } else {
            document.getElementById("autoSuggestionDiv").style.display = "none";
            setCardsData([]);
            setSelectedOldQA(null);
            setSelectedNewQA(null);
            setSelectedCard(null);
            setRatingSection(null);
            setRating(0);
        }
    }, [searchQuery]);

    const debounced = debounce(() => {
        getCardsByTitle();
    }, 1000);

    useEffect(() => {
        setSearchQuery(selectedCard?.cardTitle);
        const temp = selectedCard?.cardRatingWithQA?.find((item, i) => ratingSection === item.key);
        setSelectedOldQA(temp)
        setRating(temp?.value);
        setOptions(selectedCard?.defaultSections);
    }, [selectedCard, ratingSection])

    const submitNewQA = async () => {
        const obj = {
            cardId: selectedCard.cardId,
            newQA: selectedNewQA,
            cardType: ratingSection,
            rating: rating || selectedOldQA.value,
            ratingReason: ratingReason,
        }

        const res = await saveNewQAOnCard(obj);
        if (res.status) {
            successToast(res.data.success);
            setSelectedOldQA(null);
            setSelectedNewQA(null);
            setSelectedCard(null);
            setSearchQuery(null);
            setRatingSection(null);
            setRatingReason();
            setOptions([]);
            getRatedCards();
        } else {
            errorToast("Error Occured")
        }
    }

    const getRatedCards = async () => {
        infoToast("Loading");
        let obj = {
            startDate: toISODate(startDate),
            endDate: toISODate(endDate),
            limit,
            index,
            selectedRating,
        }
        try {
            const res = await getUpdatedRatingCards(obj);
            if (res?.status) {
							dispatch({type: UPDATED_RATING_CARDS_DATA, payload: res?.data?.updatedRatedCards});
							dispatch({type: TOTAL_RATED_CARDS, payload: res?.data?.totalRatedCards});
            } else {
                errorToast("Something went wrong");
            }
        } catch (error) {
            errorToast("Error fetching data");
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
            getRatedCards();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, selectedRating, startDate, endDate])

    return (
        <Container id="video_upload">
            <div className="aside-right-top-bar">
                <div className="top-bar-left">
                    <div className="top_bar">
                        <h3>Update QA Rating</h3>
                    </div>
                </div>
                <div className="top-bar-right">
                    <TopHeader />
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.innerContainer}>
                    <div className={styles.divWrapper}>
                        <p className={styles.inputLabel}>Search Card</p>
                        <div className={styles.autoCompleteInputWrapper}>
                            <Input.Search
                                size={isMobile ? "medium" : "large"}
                                placeholder="Search Cards"
                                suffix={<Spin spinning={isLoading} size="small" />}
                                value={searchQuery}
                                className={`${styles.searchInput} searchInputStyle`}
                                onChange={(e) => { document.getElementById("autoSuggestionDiv").style.display = "block"; setSearchQuery(e.target.value) }}
                            />
                            <div className={styles.customInputDiv} id="autoSuggestionDiv">
                                {cardsData?.map((item, i) => {
                                    return <p className={styles.optionListItem} onClick={() => { setRatingSection("done"); setSelectedCard(item); document.getElementById("autoSuggestionDiv").style.display = "none" }}>{item.cardTitle}</p>
                                })}
                                {
                                    cardsData?.length === 0 && <Empty className={styles.emptyDataElement} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.divWrapper}>
                        <p className={styles.inputLabel}>Rating For</p>
                        <Select
                            size={isMobile ? "medium" : "large"}
                            className={`${styles.viewAsStyle} selectCustomStyle`}
                            showSearch
                            optionFilterProp="children"
                            value={ratingSection}
                            onChange={(value) => setRatingSection(value)}
                            options={options}
                            placeholder="Select Section"
                        />
                    </div>
                </div>

                <div className={styles.innerContainer}>
                    <div className={styles.divWrapper}>
                        <p className={styles.inputLabel}>Old Assigned QA</p>
                        <Select
                            size={isMobile ? "medium" : "large"}
                            className={`${styles.viewAsStyle} disableSelectBox selectCustomStyle`}
                            value={selectedOldQA}
                            placeholder="Old QA"
                            disabled
                        />
                    </div>
                    <div className={styles.divWrapper}>
                        <p className={styles.inputLabel}>New Assinged QA</p>
                        <Select
                            size={isMobile ? "medium" : "large"}
                            className={`${styles.viewAsStyle} selectCustomStyle`}
                            showSearch
                            optionFilterProp="children"
                            value={selectedNewQA}
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            onChange={(value) => setSelectedNewQA(value)}
                            options={
								(websiteUsers || [])
									.filter(user => user.client_type === QA_USER && !user?.is_deleted)
									.map(user => ({
										value: user.client_id,
										label: `${getUserShortName(user.fullname)} (${user.client_type})`
									}))
                                }
                            placeholder="New QA"
                        />
                    </div>
                </div>
                <div className={styles.innerContainer}>
                    <div className={styles.divWrapper}>
                        <p className={styles.inputLabel}>Reason</p>
                        <div>
                            <Input
                                size={isMobile ? "medium" : "large"}
                                placeholder="Rating Reason"
                                suffix={<Spin spinning={isLoading} size="small" />}
                                value={ratingReason}
                                className={`${styles.searchInput} searchInputStyle`}
                                onChange={(e) => setRatingReason(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.innerContainer}>
                    <div className={styles.divWrapper}>
                        <p className={styles.inputLabel}>Rating</p>
                        <Ratings
                            rating={rating || selectedOldQA?.value}
                            widgetRatedColors="#faa64b"
                            changeRating={setRating}
                            widgetEmptyColors="lightgray"
                            widgetDimensions="40px"
                        >
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                            <Ratings.Widget />
                        </Ratings>
                    </div>
                </div>

                <div className={styles.innerContainer}>
                    <div className={styles.btnWrapper}>
                        <Button className={styles.submitButton} type="primary" disabled={!(ratingSection && ((selectedOldQA?.value !== rating && ratingReason && ratingReason.length > 5)
                            || (selectedNewQA && ratingReason && ratingReason.length > 5)))} onClick={() => submitNewQA()}>Submit</Button>
                    </div>
                </div>
            </div>
            <RatingFilter />

            <RatingTable
            />
        </Container>
    );
}

export default Rating;
