import React, { useState } from 'react';
import { Typography, Space, Button, Modal } from 'antd';
import { TextInput } from "react-materialize";
import styles from '../DetailsTab/DetailsTabStyle.module.scss';
import TimelineIcon from '../../../../components/img/timeline_icon.png';
import ArchiveImg from '../../../../components/img/archive.png';
import { PauseOutlined, PlaySquareOutlined } from '@ant-design/icons';
import Ratings from "react-ratings-declarative";
import { DONE, EDITING, ON_HOLD, YT_HELPER, YT_HELPER_IN_DONE, } from '../../../../api/Constants';
import { cardInProgress, errorToast, infoToast, isAdmin, isCustomer, successToast, getCardDisplayStatus, isMobile, primaryColor, disableColor, isSubAdmin, getIconComponent } from '../../../../api/Util';
import { axiosClient } from '../../../../api/httpClient';
import { moveCardSubStatus } from '../../../../api/CardActions';
import { SELECTED_CARD_SECTION } from '../../../../actions/types';
import { useDispatch, useSelector } from 'react-redux';

const DetailsHeader = ({ card, fetchCardDetails }) => {

    const props = useSelector((state) => state)
    const viewType = localStorage.getItem("viewType");
    const loggedInUser = props.auth?.loggedInUser;
    const [isRevisionModalOpen, setIsRevisionModalOpen] = useState(false);
    const [isRepurposeRevisionModalOpen, setIsRepurposeRevisionModalOpen] = useState(false);
    const [revisionType, setRevisionType] = useState("");
    const [isMarkDoneModalOpen, setIsMarkDoneModalOpen] = useState(false);
    const [isRatingsModalOpen, setIsRatingsModalOpen] = useState(false);
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");
    const [showRevisionButton, setRevisionButton] = useState(true);
    const dispatch = useDispatch();
    const handleOk = () => {
        setIsRevisionModalOpen(false);
        let ytHelperUrl = `/api/ythelper/card/${card?.card_id}/revision`;
        let commonCardUrl = `/api/card/${card?.card_id}/revision`;

        axiosClient.post(viewType === "yt-helper" ? ytHelperUrl : commonCardUrl,
            { card_id: card?.card_id, card_status: card?.card_status, revisionType })
            .then(({ data }) => {
                successToast(data.success || "Card moved to revision successfully");
            }).catch(err => {
                let message = "Something went wrong, please try again";
                if (err?.response?.data && err.response.data.message && err.response.data.message !== "") {
                    message = err.response.data.message;
                }
                errorToast(message);
                console.error(err);
            });
    };

    const handleCancel = () => {
        setIsRevisionModalOpen(false);
    };

    const handleRepurposeRevision = () => {
        moveCardSubStatus(card?.card_id, "editing", card?.repurpose_status, () => { });
    };

    const cancelRepurposeRevision = () => {
        setIsRepurposeRevisionModalOpen(false);
    };

    const handleOkMarkDone = () => {
        setIsMarkDoneModalOpen(false);
        let commonCardUrl = `/api/card/${card?.card_id}/markComplete`;
        let ytCardUrl = `/api/ythelper/card/${card?.card_id}/markComplete`;
        let cardSection = viewType !== "yt-helper" && card?.card_status === DONE && card?.repurpose_status === DONE ? "repurpose" : "done";

        axiosClient.post(viewType === "yt-helper" ? ytCardUrl : commonCardUrl,
            { card_id: card?.card_id, status: 1, cardSection, buttonText: card?.card_status === DONE && card?.yt_helper_status && card?.role === YT_HELPER ? "Ready For Upload" : "Mark As Done" })
            .then(({ data }) => {
                successToast(data.success || "Card marked completed!");
                setRevisionButton(false);
                viewType !== "yt-helper" && setIsRatingsModalOpen(true);
                cardSection = { cardType: "normal", section: "done", markAsDone: 1 };
                dispatch({
                    type: SELECTED_CARD_SECTION,
                    payload: { status: 200, data: cardSection }
                })
            }).catch(err => {
                let message = "Something went wrong, please try again";
                if (err?.response?.data && err.response.data.message && err.response.data.message !== "") {
                    message = err.response.data.message;
                }
                errorToast(message);
                console.error(err);
            });
    };

    const handleCancelMarkDone = () => {
        setIsMarkDoneModalOpen(false);
    };
    const handleOkRatings = () => {
        setIsRatingsModalOpen(false);
        axiosClient.post(`/api/card/${card?.card_id}/rate`,
            {
                card_id: card?.card_id,
                rating, comment,
                card_type: getCardRateType(),
                assigned_qa_id: card.assigned_qa_id,
            })
            .then(({ data }) => {
                successToast("Feedback submitted, thank you!");
                fetchCardDetails();
            }).catch(err => {
                errorToast("Something went wrong, please try again");
                console.error(err);
            });
    };

    function getCardRateType() {
        let cardType = "done";
        if (card?.is_repurpose_complete) {
            cardType = "repurpose";
        }
        return cardType;
    }
    const handleCancelRatings = () => {
        setIsRatingsModalOpen(false);
    };

    const handleOkArhiveButton = () => {
        setIsArchiveModalOpen(false);
        axiosClient.post(`/api/card/${card?.card_id}/archive`)
            .then(({ data }) => {
                successToast(data.success || "Card moved to archive successfully");
            }).catch(err => {
                errorToast("Something went wrong, please try again");
                console.error(err);
            });
    };

    const handleCancelArchiveButton = () => {
        setIsArchiveModalOpen(false);
    };


    async function pauseResumeCard(card) {
        let paused = card.paused;
        infoToast("Processing");
        return axiosClient.put(`/api/v2/card/${card.card_id}`, { paused: paused ? 0 : 1 })
            .then(({ data }) => {
                successToast(data.success || `Card ${paused ? "Resumed" : "Paused"} Successfully`);
            }).catch(err => {
                errorToast("Something went wrong, please try again");
                console.error(err);
            });
    }

    const markAsDoneButton = () => {
        if (
            (!isCustomer(loggedInUser) && !isAdmin(loggedInUser) && !isSubAdmin(loggedInUser))
            || (card?.card_status !== DONE && viewType !== "yt-helper")
            || (card?.card_status === DONE && card?.repurpose_status === EDITING)
            || (viewType === "yt-helper" && card?.yt_card_status !== YT_HELPER_IN_DONE)
            || (viewType === "yt-helper" && card?.yt_is_complete)
        ) {
            return "";
        }
        if ((viewType === "normal" && !card?.is_complete && card?.card_status === DONE) || (viewType === "normal" && !card?.is_repurpose_complete && card?.repurpose_status === DONE )) {
            return <Button type="primary" size={'large'} className={styles.readyForUploadBtn} onClick={() => setIsMarkDoneModalOpen(true)}>
                {card?.card_status === DONE && card?.yt_helper_status && card?.role === YT_HELPER ? "Ready For Upload" : "Mark As Done"}
            </Button>
        }
        return "";
    }
    return (
        <div className={styles.container}>

            <div className={styles.leftSection}>
                <Space className="detailsWrapper">
                    {getIconComponent("user-profile")}
                    <Typography.Text className={styles.greyFontColor}>
                        <span>Client: </span>{card?.client_name}
                    </Typography.Text>
                </Space>
                <Space className="detailsWrapper">
                    {getIconComponent("md-date-range")}
                    <Typography.Text className={styles.greyFontColor}>
                        <span>Created: </span>{card?.creation_time}
                    </Typography.Text>
                </Space>
                {
                    viewType === "normal" ? [
                        card?.timeline ?
                            <Space className="detailsWrapper">
                                {getIconComponent("md-timeline")}
                                <Typography.Text className={styles.greyFontColor}>
                                    <span>Timeline: </span>{card?.timeline}hrs
                                </Typography.Text>
                            </Space> : null,
                        card?.revisionCount && (card?.revisionCount.done > 0 || card?.revisionCount.repurpose > 0) ? (
                            <>
                                {card?.revisionCount.done > 0 && (
                                    <Space className="detailsWrapper">
                                        {getIconComponent("md-no")}
                                        <Typography.Text className={styles.greyFontColor}>
                                            <span>Full Video Revisions: {card?.revisionCount.done}</span>
                                        </Typography.Text>
                                    </Space>
                                )}
                                {card?.revisionCount.repurpose > 0 && (
                                    <Space className="detailsWrapper">
                                        {getIconComponent("md-no")}
                                        <Typography.Text className={styles.greyFontColor}>
                                            <span>Repurpose Revision: {card?.revisionCount.repurpose}</span>
                                        </Typography.Text>
                                    </Space>
                                )}
                            </>
                        ) : (
                            card?.revision_nr ? (
                                <Space className="detailsWrapper">
                                    {getIconComponent("md-no")}
                                    <Typography.Text className={styles.greyFontColor}>
                                        <span>Revisions: </span>{card?.revision_nr}
                                    </Typography.Text>
                                </Space>
                            ) : null
                        ),
                        card?.doneCardRating ?
                            <Space className="detailsWrapper">
                                {getIconComponent("rating")}
                                <Typography.Text className={styles.greyFontColor}>
                                    <span>Rating: </span>{card?.rating}
                                </Typography.Text>
                            </Space> : null,
                    ] : null

                }
                {
                    viewType === "yt-helper" ? [
                        card?.yt_timeline ?
                            <Space className="detailsWrapper">
                                <img src={TimelineIcon} className={styles.iconsStyle} alt="TimelineIcon" />
                                <Typography.Text className={styles.greyFontColor}>
                                    <span>Timeline: </span>{card?.yt_timeline}hrs
                                </Typography.Text>
                            </Space> : null,
                        card?.yt_card_revision ?
                            <Space className="detailsWrapper">
                                <img src={TimelineIcon} className={styles.iconsStyle} alt="TimelineIcon" />
                                <Typography.Text className={styles.greyFontColor}>
                                    <span>Revisions: </span>{card?.yt_card_revision}
                                </Typography.Text>
                            </Space> : null,
                        <Space className="detailsWrapper">
                            <img src={TimelineIcon} className={styles.iconsStyle} alt="TimelineIcon" />
                            <Typography.Text className={styles.greyFontColor}>
                                <span>Editor Status: </span>{card?.card_status === DONE && card?.yt_helper_status && card?.role === YT_HELPER && card?.is_complete ? "Ready For Upload" :
                                    card?.is_complete ? "Mark as done completed" : getCardDisplayStatus(card?.card_status)}
                            </Typography.Text>
                        </Space>
                    ] : null
                }
            </div>
            <div className={styles.rightSection}>
                {
                    (showRevisionButton && !(!(isCustomer(loggedInUser) || isAdmin(loggedInUser) || isSubAdmin(loggedInUser)) || (viewType !== "yt-helper" && card?.card_status !== DONE)
                        || (viewType !== "yt-helper" && card?.is_complete) || (viewType === "yt-helper" && card?.yt_card_status !== YT_HELPER_IN_DONE)
                        || (viewType === "yt-helper" && card?.yt_is_complete))) &&
                    <Button type="primary" size={'large'} className={styles.revisionBtn} onClick={() => setIsRevisionModalOpen(true)}>Revision</Button>
                }
                {
                    !(viewType === "yt-helper" || !(isCustomer(loggedInUser) || isAdmin(loggedInUser) || isSubAdmin(loggedInUser)) || card?.is_repurpose_complete || !(card?.card_status === DONE && card?.repurpose_status === DONE)) &&
                    <Button type="primary" size={'large'} className={styles.revisionBtn} onClick={() => { setIsRepurposeRevisionModalOpen(true); setRevisionType("repurpose") }}>Revision</Button>
                }
                {
                    !isMobile && markAsDoneButton()
                }
                {
                    (((card?.is_complete && card?.repurpose_status === null) || (card?.is_repurpose_complete && card?.repurpose_status !== null)) && viewType !== "yt-helper")
                        ?
                        <Button type="primary" size={'large'} className={styles.readyForUploadBtn} onClick={() => setIsRatingsModalOpen(true)}>
                            Rate
                        </Button>
                        : null
                }
                {
                    !(viewType === "yt-helper" || !((card?.card_status === DONE || card?.card_status === ON_HOLD) && (isAdmin(loggedInUser) || isSubAdmin(loggedInUser) ||(isCustomer(loggedInUser) && card?.is_complete)))) &&
                    <Button type="primary" className={styles.archiveBtn} size={'large'} onClick={() => setIsArchiveModalOpen(true)}>
                        <img src={ArchiveImg} alt="ArchiveImg" />
                    </Button>
                }
                {
                    !(viewType === "yt-helper" || !isAdmin(loggedInUser) || (!cardInProgress(card) && card.card_status !== ON_HOLD)) &&
                    <Button type="primary" onClick={() => pauseResumeCard(card)} icon={card.paused ? <PlaySquareOutlined className={styles.pauseIcon} /> : <PauseOutlined className={styles.pauseIcon} />} className={styles.pauseBtn} size={'large'} />
                }
                {/* {
        (isCustomer(loggedInUser) || isAdmin(loggedInUser)) 
        && ( currentVideoUrl && (currentVideoUrl.search("vidchops-videos.s3-accelerate.amazonaws.com") !== -1 || currentVideoUrl.search("digitaloceanspaces.com/") !== -1 ) ) ? 
            isMobile ? <Button type="primary" icon={<DownloadOutlined />} className={styles.downloadBtnIcon} onClick={()=>window.open(currentVideoUrl)} /> : <Button type="primary" size={'large'} className={styles.downloadBtnText} onClick={()=>window.open(currentVideoUrl)}>
                Download
            </Button> :null
    } */}
            </div>
            {isMobile &&
                <div className={styles.mobileMarkDoneBtn}>
                    {markAsDoneButton()}
                </div>
            }

            <Modal className={'modalStyle'} title="Revision" open={isRevisionModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Typography.Text className={`${styles.greyFontColor} ${styles.containerForVideoDescription}`}>
                    {`Requesting a revision for: ${card?.card_title}. Are you sure you want to proceed?`}
                </Typography.Text>
            </Modal>
            <Modal className={'modalStyle'} title="Revision" open={isRepurposeRevisionModalOpen} onOk={handleRepurposeRevision} onCancel={cancelRepurposeRevision}>
                <Typography.Text className={`${styles.greyFontColor} ${styles.containerForVideoDescription}`}>
                    {`Moving card "${card?.card_title}" to repurpose. Are you sure you want to proceed?`}
                </Typography.Text>
            </Modal>
            <Modal className={'modalStyle'} title={card?.card_status === DONE && card?.yt_helper_status && card?.role === YT_HELPER ? "Ready For Upload" : "Mark As Done"} open={isMarkDoneModalOpen} onOk={handleOkMarkDone} onCancel={handleCancelMarkDone}>
                <Typography.Text className={`${styles.greyFontColor} ${styles.containerForVideoDescription}`}>
                    {`Mark ${card?.card_title} as done?`}
                </Typography.Text>
            </Modal>
            <Modal className={'modalStyle'} title="Feedback" open={isRatingsModalOpen} onOk={handleOkRatings} onCancel={handleCancelRatings}
                footer={[
                    <Button key="back" onClick={handleCancelRatings}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" disabled={rating === 0} onClick={handleOkRatings}>
                        OK
                    </Button>
                ]}>
                <Ratings
                    rating={rating}
                    changeRating={setRating}
                    widgetRatedColors={primaryColor}                            
                    widgetEmptyColors={disableColor}
                    widgetHoverColors={primaryColor}
                >
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                </Ratings>
                <TextInput s={12} label="Any feedback?" icon="short_text" value={comment}
                    id={"txt-feedback-" + card?.card_id}
                    onChange={e => setComment(e.target.value)} />
            </Modal>
            <Modal className={'modalStyle'} title="Archive" open={isArchiveModalOpen} onOk={handleOkArhiveButton} onCancel={handleCancelArchiveButton}>
                <Typography.Text className={`${styles.greyFontColor} ${styles.containerForVideoDescription}`}>
                    {`Moving card ${card?.card_title} to archive. Are you sure you want to proceed?`}
                </Typography.Text>
            </Modal>
        </div>

    );

}
export default DetailsHeader;
